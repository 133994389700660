interface Update {
  id: string;
  type: string;
  title: string;
  description: string;
  imageUrl: string;
  link?: string;
}

export const updates: Update[] = [
  {
    id: "5",
    type: "Virtual Session",
    title: "Masterclass (Pioneers in the Waste & Circularity Sector)",
    description:
      "Date and Time: Thursday, February 14, 2025 | 5:00 PM to 6:30 PM",
    imageUrl:
      "https://prod-gti.s3.ap-south-1.amazonaws.com/events/masterclass_pioneers_in_the_waste_and_circularity_sector.png",
    link: "#",
  },
  {
    id: "4",
    type: "Virtual Session",
    title: "Masterclass (Unlocking B2G opportunities)",
    description:
      "Date and Time: Thursday, February 6, 2025 | 5:00 PM to 6:30 PM",
    imageUrl:
      "https://prod-gti.s3.ap-south-1.amazonaws.com/event_image/masterclass.png",
    link: "#",
  },
  {
    id: "3",
    type: "Virtual Session",
    title: "Strategies for scale and investment Readiness Checklist",
    description:
      "Date and Time: Thursday, January 30, 2025 | 5:00 PM to 6:30 PM",
    imageUrl:
      "https://prod-gti.s3.ap-south-1.amazonaws.com/event_image/Strategies-for-scale-and-investment-readiness-checklist.jpeg",
    link: "#",
  },
  {
    id: "2",
    type: "Virtual Session",
    title: "Unit Economics & Revenue and Cost Drivers",
    description:
      "Date and Time: Thursday, January 23, 2025 | 5:00 PM to 6:30 PM",
    imageUrl:
      "https://prod-gti.s3.ap-south-1.amazonaws.com/event_image/Unit-Economics-and-Revenue-Cost-Drivers.png",
    link: "#",
  },
  {
    id: "1",
    type: "Virtual Session",
    title: "Program Kick off &  Orientation + GTI Walkthrough",
    description:
      "Date and Time: Thursday, January 16, 2025 | 5:00 PM to 6:30 PM",
    imageUrl:
      "https://prod-gti.s3.ap-south-1.amazonaws.com/event_image/GTI+VIRTUAL.png",
    link: "https://drive.google.com/file/d/1N3fWjkVsmG74Ky2sW3jl0uF-77lfz0fY/view",
  },
  {
    id: "6787b23fbb84e4004f255391",
    type: "Event",
    title: "BHARAT TEX 2025",
    description:
      "Experience the best of trade, technology, and tradition at BHARAT TEX 2025, a global textile trade fair organized by 12 Indian Textile Export Promotion Councils (EPCs) and supported by the Ministry of Textiles, Government of India.",
    imageUrl:
      "https://prod-gti.s3.ap-south-1.amazonaws.com/event_image/1736946238543-tex.png",
    link: "https://www.globaltechinterface.com/events/6787b23fbb84e4004f255391",
  },
  {
    id: "67876471bb84e4004f2544f4",
    type: "Event",
    title: "Sustainable Solutions Match",
    description:
      "Join SustainableSolutionsMatch 2025, a dynamic virtual event running from 7th to 21st February 2025, designed to connect solution seekers and providers across Europe. This event aims to drive tangible impact by fostering collaboration and innovation for a sustainable future.",
    imageUrl:
      "https://prod-gti.s3.ap-south-1.amazonaws.com/event_image/1736926320140-sustainable.png",
    link: "https://www.globaltechinterface.com/events/67876471bb84e4004f2544f4",
  },
  {
    id: "6786014dbb84e4004f25160d",
    type: "Event",
    title: "Building Circularity Conference 2025",
    description:
      "Join us at the Building Circularity Conference 2025, taking place from 19th to 20th February 2025 in Warsaw.",
    imageUrl:
      "https://prod-gti.s3.ap-south-1.amazonaws.com/event_image/1736835404946-warsaw.png",
    link: "https://www.globaltechinterface.com/events/6786014dbb84e4004f25160d",
  },
  {
    id: "6784cfa2bb84e4004f24f88e",
    type: "Event",
    title: "Global Textile Technology & Engineering Show (GTTES) 2025",
    description:
      "Discover innovation and opportunity at the 3rd Global Textile Technology & Engineering Show (GTTES) - a premier exhibition designed to address the evolving needs of the textile industry.",
    imageUrl:
      "https://prod-gti.s3.ap-south-1.amazonaws.com/event_image/1736757153435-GTTES.png",
    link: "https://www.globaltechinterface.com/events/6784cfa2bb84e4004f24f88e",
  },
  {
    id: "67865b27bb84e4004f2524f2",
    type: "Event",
    title: "SelectUSA Investment Summit 2025",
    description:
      "Be part of the SelectUSA Investment Summit 2025, the premier event in the United States dedicated to facilitating business investment.",
    imageUrl:
      "https://prod-gti.s3.ap-south-1.amazonaws.com/event_image/1736858406642-selectusa.png",
    link: "https://www.globaltechinterface.com/events/67865b27bb84e4004f2524f2",
  },
];
